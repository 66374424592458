import { ThemeUIStyleObject } from "theme-ui";

export const text: Record<string, ThemeUIStyleObject> = {
  title: {
    fontSize: 1,
    textTransform: "uppercase",
    color: "secondary",
    lineHeight: 1.5,
  },
  heading: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
  },
  h1: {
    variant: "text.heading",
    color: "secondary",
    fontSize: [7, null, 8], //48px 54px
    textAlign: "center",
  },
  h2: {
    variant: "text.heading",
    color: "primary",
    fontSize: 6, //36px
    textAlign: "center",
  },
  h3: {
    variant: "text.heading",
    color: "primary",
    fontSize: 5, //32px
  },
  h4: {
    variant: "text.heading",
    color: "secondary",
    fontSize: 4, //26px
  },
  h5: {
    color: "secondary",
    variant: "text.heading",
    fontSize: [2, null, 3], // 20px  22px
    lineHeight: 1.5,
  },
  body: {
    fontFamily: "body",
    fontWeight: "body",
    lineHeight: "body",
    letterSpacing: "body",
    color: "text",
  },
  basic: {
    variant: "text.body",
    fontSize: 1,
    lineHeight: 1.7,
  },
  disclaimer: {
    variant: "text.body",
    fontSize: 0,
    color: "brand.black",
  },
  link: {
    textDecoration: "underline",
    color: "primary",
  },
};
