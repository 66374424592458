const fonts: { [k: string]: string } = {
  kabel: "neue-kabel, serif",
  sans: "Open Sans",
  monospace:
    'Menlo, "Cascadia Code", "Operator Mono", "Fira Code", "Dank Mono", Consolas, monospace',
};
fonts.body = fonts.sans;
fonts.heading = fonts.kabel;

const fontSizes = [14, 16, 20, 22, 26, 32, 36, 48, 54].map(
  (px) => `${px / 16}rem`
);
const fontWeights = {
  body: 400,
  medium: 500,
  semibold: 600,
  heading: 700,
  bold: 700,
  extrabold: 900,
};
const lineHeights = {
  body: 1.5,
  heading: 1.2,
};
const letterSpacings = {
  body: "normal",
  heading: "-0.03em",
  button: "1.0667px",
  caps: "0.2em",
};

export { fonts, fontSizes, fontWeights, lineHeights, letterSpacings };
