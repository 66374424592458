const grays = [
  '#F8F9F9',
  '#F9F9F9',
  '#EBEDED',
  '#DDE1E1',
  '#CDD4D3',
  '#BDC5C4',
  '#AAB5B4',
  '#95A2A1',
  '#7B8C8A',
  '#5B706E',
  '#334240',
]

const brand = {
  // black-grey tones
  textBlack: '#383739',
  black: '#231F20',
  darkGrey: '#8C8B8B',
  grey: '#B1B2B3',
  lightGrey: '#E7E7E7',
  bgGrey: '#F8F9FA',
  //blue shades
  skyBlue: '#29AAE2',
  skyBlue50: '#6DCFF6',
  skyBlue25: '#D4EEF2',
  skyBlue10: '#F0FAFE',
  navyBlue: '#03244D',
  // purple shades
  darkPurple: '#37315D',
  purple: '#42368E',
  palePurple: '#5E5A7E',
  // red shades
  red: '#D91828',
  red50: '#EC8B93',
  red25: '#FAE2E4',
  red10: '#FDF3F4',
  darkRed: '#BF0030',
  // yellow shades
  yellow: '#FFDD00',
  yellow50: '#FFEE7F',
  yellow25: '#FFF7BF',
  yellow10: '#FFFBE7',
  darkYellow: '#FCC805',
}

export const colors = {
  text: brand.black,
  background: brand.bgGrey,
  primary: brand.red,
  secondary: brand.black,
  accent: brand.yellow,
  placeholder: brand.yellow10,
  brand,
}
