import * as React from "react";
import {
  ProductProvider,
  ProductContextConfig,
} from "./src/cobra-products/contexts/productContext";

// interface RootElement {
//   element: JSX.Element
// }

export const wrapRootElement = (
  { element } /*: RootElement*/,
  pluginOptions /*: ProductContextConfig*/
) => {
  return <ProductProvider {...pluginOptions}>{element}</ProductProvider>;
};
